<template>
	<div>
		<!-- <headertop/>
		<headers/> -->
		
		<div class="w-1200 m-a">
			<div class="l-h-40 h-40 p-t-13">
			   <el-breadcrumb separator-class="el-icon-arrow-right">
			        <el-breadcrumb-item :to="{ path: '/' }">你所在的位置：首页</el-breadcrumb-item>   
			        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
			    </el-breadcrumb>
				<!-- 你所在的位置 -->
			</div>
			
			<div class="flex">
				<div class="w-260 bg-f p-0-20">
					<div class="m-b-10" v-for="(item,k) in navitem">
						<h1 class="h-55 l-h-55 f-16 flex start bold"><i class="shu m-t-18 m-r-10 --bg h-16"></i> {{item.name}}</h1>
						<div class="flex warp">
							<div class="w50 cursor tl m-b-18 p-l-20" :class="check==('tb'+k+'-'+index)?'--color bold':''"  v-for="(item,index) in newslists[item.value]"  :key="index" @click="detailnews(item,k,index)">{{item.title}}</div>
						</div>
					</div>
				</div>
				
				<div class="w-920 bg-f">
					<div class="m-b-20">
						<h3 class="bg-f p-l-20 title l-h-60 border-b f-20 bold">{{detail.title}}</h3>
								
						<!-- <div class="bg-f contents m-t-10" v-if="detail">
							<h3 class="f-24 m-b-20 tc "> {{detail.title}}</h3>
							<p class="f-12 m-b-15 tc ">发布时间：{{detail.createdate}}</p>
							<p class="border-b <c-6></c-6>">分类：{{newsname}}</p>
						</div> -->
						<div class="bg-f o-h p-l-20">
							<p class="p-t-20 p-r-20 m-a l-h-23" v-html="detail.conentString">{{detail.conentString}}</p>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		
		<div class="h-40">
			<!-- <footers/> -->
		</div>
	</div>
	
</template>


<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	export default{
		components:{footers,headertop,headers},
		data() {
			return {
				newslists: this.$store.state.app.newslist,
				newslistshow: [],
				newsname: '',
				
				navitem:[
					{name:'关于我们',value:'GYWM'},
					{name:'新手帮助',value:'XSBZ'},
					{name:'售后服务',value:'SHFW'},
					{name:this.$store.state.app.Operational.index.paytype,value:'ZFFS'}
				],
				detail:'',
				showdetail:this.$route.query.showdetail,
				
				check:'tb0-0',
				checkid:''
			};
		},
		watch: {
			$route(){
				this.init()
			}
		},
		created(){
			
			this.getTenantid(res=>{
				this.getRichtext()
				this.init()
			})
		},
		
		methods:{
			init(){
					console.log(8989898,this.$route.query)
				if(this.$route.query.check){
					this.check=this.$route.query.check
					this.checkid=this.$route.query.detailid
				}else{
					var newslist = sessionStorage.getItem('newslist');
					this.newslists=newslist?JSON.parse(newslist):[]
					this.checkid=this.newslists.GYWM[0].id
				}
				
				this.newsDetail()
			},
			
			detailnews(item ,k,i){
				console.log(item)
				this.checkid=item.id
				this.check='tb'+k+'-'+i
				this.newsDetail()
			},
			
			newsDetail() {
				var self = this;
				var id = this.checkid;
				var url = '/kbhelper/findarticle';
				var data = {id: id?id:this.$route.query.detailid}; 
				this.requset(url, data).then((res)=> {
					console.log(res)
					self.detail=''
					if(res.status=="success"){																																																																																																						
						
						self.detail = res.data;
					}else{
						self.detail ='';
					}
				
					
				});
				
			}
			
			
			
		}
	}
	
</script>

<style>
	.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
		font-weight: unset;
		
	}
</style>